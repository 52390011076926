<template>
  <div class="user">
    <div class="user">
      <!-- 小盒子1 -->
      <div class="utop">
        <div class="ucet">
          <div class="userTop">
            <!--  小盒子1 左侧-->
            <div class="userTopLeft">
              <img :src="detail.img" alt="" />
              <div>
                <p>欢迎回来 {{ detail.nickName }}</p>
                <!-- <p class="hi">
                  {{
                    detail.autograph
                      ? detail.autograph
                      : '这个人很懒，什么都没有留下'
                  }}
                </p> -->
              </div>
            </div>
            <!-- <p>张凯凯</p> -->
          </div>
        </div>
      </div>
      <div class="conmain">
        <div class="c_nav">
          <div class="nav_center">
            <div
              class="userCentLefts"
              v-for="(item, index) in list"
              :key="index"
            >
              <div
                class="userCentLeftsC"
                v-for="(chil, chilIndex) in item.chil"
                :key="chilIndex"
                :class="[tabId == chil.id ? 'on' : '']"
              >
                <div
                  :class="[tabId == chil.id ? 'ssk' : '']"
                  class="userCentLeftsCr"
                  @click.stop="tabChange(chil)"
                >
                  <i
                    class="iconfont"
                    :class="[tabId == chil.id ? 'ccc' : '', chil.icon]"
                  />{{ chil.title }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content">
          <div class="box-content">
            <!-- 小盒子2 -->
            <div class="userCent">
              <!-- 右侧盒子 -->
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import { getUserCourseStatistics } from "@/api/user";
export default {
  components: {},

  data() {
    return {
      m: "",
      tel: "",
      uid: "",
      userId: "",
      list: [
        {
          title: "个人主页",
          chil: [
            {
              id: 2,
              title: "个人资料",
              url: "/mysixth/myInfo",
              icon: "icon-bianjigerenziliao",
            },
          ],
        },
        {
          title: "订单管理",
          chil: [
            {
              id: 3,
              title: "我的订单",
              url: "/mysixth/mineOrder",
              icon: "icon-a-Group1530",
            },
            {
              id: 4,
              title: "优惠券",
              url: "/mysixth/mineCoupon",
              icon: "icon-youhuiquan1",
            },
            {
              id: 6,
              title: "兑换券",
              url: "/mysixth/mineExchange",
              icon: "icon-youhuiquan1",
            },
          ],
        },
        {
          title: "学习卡",
          chil: [
            {
              id: 9,
              title: "学习卡",
              url: "/mysixth/myCard",
              icon: "icon-xuexika",
            },
          ],
        },
        {
          title: "意见反馈",
          chil: [
            {
              id: 7,
              title: "意见反馈",
              url: "/mysixth/feedback",
              icon: "icon-yijianfankui1",
            },
          ],
        },
        {
          title: "账户设置",
          chil: [
            {
              id: 8,
              title: "账户设置",
              url: "/mysixth/accountSetting",
              icon: "icon-zhanghushezhi",
            },
          ],
        },
        {
          title: "消息管理",
          chil: [
            {
              id: 5,
              title: "我的消息",
              url: "/mysixth/mineNews",
              icon: "icon-a-Group1533",
            },
          ],
        },
        {
          title: "我的收藏",
          chil: [
            {
              id: 10,
              title: "我的收藏",
              url: "/mysixth/collect",
              icon: "icon-shoucangxing",
            },
          ],
        },
      ],
      detail: {
        img: "",
        nickName: "",
      },
      tabId: 1,
      chong: {},
    };
  },
  watch: {
    //使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      if (oldVal.path == "/login" || newVal.path == "/login") {
        this.userId = localStorage.getItem("userId");
      }
    },
  },
  created() {
    this.userId = localStorage.getItem("userId");
    if (!this.userId) {
      Vue.prototype.goLoginView("mustBack");
      return;
    }
    for (let item of this.list) {
      for (let chil of item.chil) {
        if (this.$route.path == chil.url) {
          this.tabId = chil.id;
          break;
        }
      }
    }

    this.detail = getInfo();
    // 个人中心 顶部数据
    getUserCourseStatistics(this.userId).then((res) => {
      this.chong = res.msg;
    });

    //滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        let top = $(window).scrollTop();
        if (top >= 290) {
          $(".userCentLeft").addClass("fixed-card");
          $(".zhicheng").removeClass("dis-none");
        } else {
          $(".userCentLeft").removeClass("fixed-card");
          $(".zhicheng").addClass("dis-none");
        }
      });
    });
  },
  methods: {
    tabChange(val) {
      if (this.tabId == val.id) {
        return;
      }
      this.titles = val.title;
      this.tabId = val.id;
      this.$router.replace(val.url);
    },
  },
};
</script>
<style lang="less" scoped>
.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}
.el-input.el-input__inner {
  width: 100px;
}
.user {
  width: 100%;
  height: 100%;
  // 大盒子
  .user {
    width: 100%;
    height: auto;
    padding-bottom: 36px;
    .utop {
      box-sizing: border-box;
      width: 100%;
      height: 160px;
      background-image: url("../../assets/img/homesixth/Group 4107.png");
      background-size: cover;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      .ucet {
        width: 1220px;
        margin: 0 auto;
        .userTop {
          height: 100px;
          width: 100%;
          background-color: rgba(255, 255, 255, 0);
          display: flex;
          // align-items: center;
          // 左侧
          .userTopLeft {
            width: 100%;

            // 头像
            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              border: 1px solid #ff5e51;
              margin: 0 auto;
              margin-top: 25px;
            }
            //  昵称
            p {
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              margin-top: 12px;
            }
            .hi {
              height: 16px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular;
              font-weight: 400;
              color: #999999;
              line-height: 16px;
              margin-top: 24px;
            }
          }
          // 右侧
          .userTopRight {
            width: auto;
            height: 45px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            // 斜线
            .userTopCents {
              width: 1px;
              height: 100%;
              background: #efefef;
              transform: rotate(20deg);
              margin: 0px 30px;
            }
            // 内容
            .userTopCent {
              width: auto;
              height: 100%;
              div {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
              }
              p {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                span {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
    .conmain {
      width: 1220px;
      margin: 0 auto;
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
    }
    .c_nav {
      width: 280px;
      height: 460px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      .nav_center {
        width: 280px;
        padding-top: 16px;
        .userCentLefts {
          .userCentLeftsC {
            width: 248px;
            height: 48px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            line-height: 48px;
            margin-left: 16px;

            .userCentLeftsCr {
              cursor: pointer;
              padding-left: 24px;

              border-radius: 8px 8px 8px 8px;
              .iconfont {
                font-size: 16px;
                margin-right: 8px;
                flex-shrink: 0;
              }
            }
            .ssk {
              color: #1061ff;
              background: #ebf2ff;
            }
          }
        }
      }
    }
    .mantil {
      height: 104px;
      line-height: 104px;
      font-size: 24px;
      font-family: Microsoft YaHei-Bold;
      font-weight: bold;
      color: #333333;
      text-align: left;
    }
    // 小盒子2
    .userCent {
      width: 100%;
      height: auto;
      margin: auto;
      background-color: #fff;
      // 小盒子2左侧
      .userCentLeft {
        // 单个模块盒子
        .userCentLefts {
          width: 100%;
          height: auto;
          margin-top: 27px;
          // 单个模块盒子标题
          p {
            width: 100%;
            height: 40px;
            font-size: 16px;
            text-align: center;
            line-height: 40px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            margin-left: 3px;
          }
          // 单个模块盒子内容
          .userCentLeftsC {
            width: 100%;
            height: 36px;
            // 右侧列表内容
            .userCentLeftsCr {
              width: 100%;
              height: 36px;
              line-height: 36px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              cursor: pointer;
              padding-left: 58px;
              border-left: 3px solid #fff;
            }
          }
          // 单个模块盒子内容点击后样式
          .on {
            background: #ebf2ff;
            .userCentLeftsCr {
              color: #1061ff;
            }
          }
        }
      }
    }
  }
}
.zhicheng {
  width: 180px;
  height: 100%;
  margin-right: 20px;
  flex-shrink: 0;
}
.ccc {
  color: #1061ff;
}
.content {
  width: 920px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  .youlove {
    width: 320px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    flex-shrink: 0px;
    margin-top: 104px;
    padding: 20px;
  }
}
</style>
